import Vue from 'vue'
import VueRouter from 'vue-router'
import NewsList from '../components/NewsList.vue'
import LoginPage from '../components/LoginPage.vue'
import Contact from '../components/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang?',
    name: 'Home',
    component: NewsList,
    props: true
  },
  {
    path: '/:lang?/login',
    name: 'Login',
    component: LoginPage,
    props: true
  },
  {
    path: '/:lang?/news/:id',
    name: 'NewsArticle',
    component: () => import('../components/NewsArticle.vue'),
    props: true
  },
  {
    path: '/:lang?/contact',
    name: 'Contact',
    component: Contact,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  if (to.path === '/contact') {
    next()
  } else if (!['en', 'zh', 'ko', 'vi', 'ar', 'ru', 'de', 'fr'].includes(lang)) {
    return next(`/en${to.fullPath}`)
  } else if (to.path === `/${lang}/login` && localStorage.getItem('token')) {
    next(`/${lang}`)
  } else {
    next()
  }
})

export default router