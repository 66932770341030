<template>
    <header>
      <h1>{{ headerText }}</h1>
      <nav>
        <router-link :to="{ name: 'Home', params: { lang: language } }">{{ getHomeText }}</router-link> |
        <router-link :to="{ name: 'Contact', params: { lang: language } }">{{ getContactText }}</router-link>
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    name: 'Header',
    props: {
      language: {
        type: String,
        default: 'en'
      }
    },
    computed: {
      headerText() {
        switch(this.language) {
          case 'en': return 'Russian News';
          case 'zh': return '俄罗斯新闻';
          case 'ko': return '러시아 뉴스';
          case 'vi': return 'Tin tức Nga';
          case 'ar': return 'الأخبار الروسية';
          case 'de': return 'Russische Nachrichten';
          case 'fr': return 'Actualités Russes';
          default: return 'Российские новости';
        }
      },
      getHomeText() {
        switch(this.language) {
          case 'en': return 'Home';
          case 'zh': return '首页';
          case 'ko': return '홈';
          case 'vi': return 'Trang chủ';
          case 'ar': return 'الرئيسية';
          case 'de': return 'Startseite';
          case 'fr': return 'Accueil';
          default: return 'Главная';
        }
      },
      getContactText() {
        switch(this.language) {
          case 'en': return 'Contact';
          case 'zh': return '联系';
          case 'ko': return '연락처';
          case 'vi': return 'Liên hệ';
          case 'ar': return 'اتصل بنا';
          case 'de': return 'Kontakt';
          case 'fr': return 'Contact';
          default: return 'Контакты';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  header {
    background-color: #2c3e50;
    color: white;
    text-align: center;
    padding: 1em;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  nav {
    margin-top: 10px;
  }

  nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }

  nav a:hover {
    text-decoration: underline;
  }

  body.dark-theme header {
    background-color: #1a2634;
  }
  </style>