<template>
  <div class="news-item">
    <img v-if="item.photo_url" :src="item.photo_url" alt="News image" class="news-image">
    <div v-if="!editing">
      <router-link :to="{ name: 'NewsArticle', params: { lang: language, id: item.id } }">
        <h2>{{ getTitle }}</h2>
      </router-link>
      <p class="date">
        {{ getPublishedText }} {{ formatDateTime(item.createdAt) }}
        <span v-if="item.updatedAt && item.updatedAt !== item.createdAt">
          ({{ getEditedText }} {{ formatDateTime(item.updatedAt) }})
        </span>
      </p>
      <p class="source" v-if="item.source">
        {{ getSourceText }} <a :href="item.source" target="_blank" rel="noopener noreferrer">{{ item.source }}</a>
      </p>
      <pre class="content">{{ getContent }}</pre>
      <button @click.stop="startEditing" v-if="isAdmin">{{ getEditText }}</button>
      <button @click.stop="deleteNews" v-if="isAdmin">{{ getDeleteText }}</button>
      <StructuredData :newsItem="item" />
    </div>
    <div v-else>
      <input v-model="editedItem.title" :placeholder="getTitlePlaceholder">
      <input v-model="editedItem.source" :placeholder="getSourcePlaceholder">
      <textarea v-model="editedItem.content" :placeholder="getContentPlaceholder"></textarea>
      <button @click="saveEdit">{{ getSaveText }}</button>
      <button @click="cancelEdit">{{ getCancelText }}</button>
    </div>
  </div>
</template>

<script>
import StructuredData from './StructuredData.vue';

export default {
  name: 'NewsItem',
  components: {
    StructuredData
  },
  props: {
    item: Object,
    isAdmin: Boolean,
    language: {
      type: String,
      default: 'en',
      validator: (value) => ['en', 'zh', 'ko', 'ru', 'vi', 'ar', 'de', 'fr'].includes(value)
    }
  },
  data() {
    return {
      editing: false,
      editedItem: {}
    }
  },
  computed: {
    getTitle() {
      switch(this.language) {
        case 'en': return this.item.titleEn || this.item.title;
        case 'zh': return this.item.titleZh || this.item.title;
        case 'ko': return this.item.titleKo || this.item.title;
        case 'vi': return this.item.titleVi || this.item.title;
        case 'ar': return this.item.titleAr || this.item.title;
        case 'ru': return this.item.titleRu || this.item.title;
        case 'de': return this.item.titleDe || this.item.title;
        case 'fr': return this.item.titleFr || this.item.title;
        default: return this.item.title;
      }
    },
    getContent() {
      switch(this.language) {
        case 'en': return this.item.contentEn || this.item.content;
        case 'zh': return this.item.contentZh || this.item.content;
        case 'ko': return this.item.contentKo || this.item.content;
        case 'vi': return this.item.contentVi || this.item.content;
        case 'ar': return this.item.contentAr || this.item.content;
        case 'ru': return this.item.contentRu || this.item.content;
        case 'de': return this.item.contentDe || this.item.content;
        case 'fr': return this.item.contentFr || this.item.content;
        default: return this.item.content;
      }
    },
    getPublishedText() {
      return this.language === 'en' ? 'Published:' : 
             this.language === 'zh' ? '发布时间：' : 
             this.language === 'ko' ? '게시일:' : 
             this.language === 'vi' ? 'Đã đăng:' :
             this.language === 'ar' ? 'نشرت:' :
             this.language === 'de' ? 'Veröffentlicht:' :
             this.language === 'fr' ? 'Publié:' :
             'Опубликовано:';
    },
    getSourceText() {
      return this.language === 'en' ? 'Source:' : 
             this.language === 'zh' ? '来源：' : 
             this.language === 'ko' ? '출처:' : 
             this.language === 'vi' ? 'Nguồn:' :
             this.language === 'ar' ? 'المصدر:' :
             this.language === 'de' ? 'Quelle:' :
             this.language === 'fr' ? 'Source:' :
             'Источник:';
    },
    getEditText() {
      return this.language === 'en' ? 'Edit' : 
             this.language === 'zh' ? '编辑' : 
             this.language === 'ko' ? '편집' : 
             this.language === 'vi' ? 'Chỉnh sửa' :
             this.language === 'ar' ? 'تعديل' :
             this.language === 'de' ? 'Bearbeiten' :
             this.language === 'fr' ? 'Éditer' :
             'Редактировать';
    },
    getDeleteText() {
      return this.language === 'en' ? 'Delete' : 
             this.language === 'zh' ? '删除' : 
             this.language === 'ko' ? '삭제' : 
             this.language === 'vi' ? 'Xóa' :
             this.language === 'ar' ? 'حذف' :
             this.language === 'de' ? 'Löschen' :
             this.language === 'fr' ? 'Supprimer' :
             'Удалить';
    },
    getTitlePlaceholder() {
      return this.language === 'en' ? 'Title' : 
             this.language === 'zh' ? '标题' : 
             this.language === 'ko' ? '제목' : 
             this.language === 'vi' ? 'Tiêu đề' :
             this.language === 'ar' ? 'عنوان' :
             this.language === 'de' ? 'Titel' :
             this.language === 'fr' ? 'Titre' :
             'Заголовок';
    },
    getSourcePlaceholder() {
      return this.language === 'en' ? 'Source' : 
             this.language === 'zh' ? '来源' : 
             this.language === 'ko' ? '출처' : 
             this.language === 'vi' ? 'Nguồn' :
             this.language === 'ar' ? 'مصدر' :
             this.language === 'de' ? 'Quelle' :
             this.language === 'fr' ? 'Source' :
             'Источник';
    },
    getContentPlaceholder() {
      return this.language === 'en' ? 'Content' : 
             this.language === 'zh' ? '内容' : 
             this.language === 'ko' ? '내용' : 
             this.language === 'vi' ? 'Nội dung' :
             this.language === 'ar' ? 'محتوى' :
             this.language === 'de' ? 'Inhalt' :
             this.language === 'fr' ? 'Contenu' :
             'Содержание';
    },
    getSaveText() {
      return this.language === 'en' ? 'Save' : 
             this.language === 'zh' ? '保存' : 
             this.language === 'ko' ? '저장' : 
             this.language === 'vi' ? 'Lưu' :
             this.language === 'ar' ? 'حفظ' :
             this.language === 'de' ? 'Speichern' :
             this.language === 'fr' ? 'Enregistrer' :
             'Сохранить';
    },
    getCancelText() {
      return this.language === 'en' ? 'Cancel' : 
             this.language === 'zh' ? '取消' : 
             this.language === 'ko' ? '취소' : 
             this.language === 'vi' ? 'Hủy' :
             this.language === 'ar' ? 'إلغاء' :
             this.language === 'de' ? 'Abbrechen' :
             this.language === 'fr' ? 'Annuler' :
             'Отмена';
    },
    getEditedText() {
      switch(this.language) {
        case 'en': return 'Edited:';
        case 'zh': return '编辑时间：';
        case 'ko': return '편집됨:';
        case 'ru': return 'Отредактировано:';
        case 'vi': return 'Đã chỉnh sửa:';
        case 'ar': return 'تم التحرير:';
        case 'de': return 'Bearbeitet:';
        case 'fr': return 'Édité:';
        default: return 'Edited:';
      }
    }
  },
  methods: {
    formatDateTime(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      return new Intl.DateTimeFormat(this.getLocale(), options).format(date);
    },
    getLocale() {
      switch(this.language) {
        case 'en': return 'en-US';
        case 'zh': return 'zh-CN';
        case 'ko': return 'ko-KR';
        case 'vi': return 'vi-VN';
        case 'ar': return 'ar-SA';
        case 'de': return 'de-DE';
        case 'fr': return 'fr-FR';
        default: return 'ru-RU';
      }
    },
    getLocalizedTimezone() {
      switch(this.language) {
        case 'en': return 'MSK';
        case 'zh': return '莫斯科时间';
        case 'ko': return '모스크바 시간';
        case 'vi': return 'Giờ Moskva';
        case 'ar': return 'توقيت موسكو';
        case 'de': return 'Moskauer Zeit';
        case 'fr': return 'Heure de Moscou';
        default: return 'МСК';
      }
    },
    startEditing() {
      this.editedItem = { 
        ...this.item,
        title: this.getTitle,
        content: this.getContent
      };
      this.editing = true;
    },
    async saveEdit() {
      try {
        await this.$emit('update', this.editedItem);
        this.editing = false;
        console.log('Edit saved successfully');
      } catch (error) {
        console.error('Error saving edit:', error);
        alert('Failed to save edit. Please try again.');
      }
    },
    cancelEdit() {
      this.editing = false;
    },
    deleteNews() {
      this.$emit('delete', this.item.id);
    }
  }
}
</script>

<style scoped>
.news-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5em;
  margin-bottom: 1.5em;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease, background-color 0.3s, color 0.3s;
}

.news-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.dark-theme .news-image {
  filter: brightness(0.8);
}

.dark-theme .news-item {
  background-color: #2c2c2c;
  border-color: #444;
  box-shadow: 0 2px 4px rgba(255,255,255,0.1);
}

.news-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.dark-theme .news-item:hover {
  box-shadow: 0 4px 8px rgba(255,255,255,0.15);
}

h2 {
  color: #333333;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.dark-theme h2 {
  color: #f0f0f0;
}

.date, .source {
  font-style: italic;
  color: #666;
  font-size: 0.9em;
  margin-bottom: 0.5em;
}

.dark-theme .date, .dark-theme .source {
  color: #aaa;
}

.source a {
  color: #3498db;
  text-decoration: none;
}

.dark-theme .source a {
  color: #58a6ff;
}

.source a:hover {
  text-decoration: underline;
}

.content {
  color: #333333;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

.dark-theme .content {
  color: #f0f0f0;
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5em 1em;
  margin-right: 0.5em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

input, textarea {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333333;
}

.dark-theme input, .dark-theme textarea {
  background-color: #444;
  color: #f0f0f0;
  border-color: #666;
}
</style>