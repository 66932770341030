<template>
    <div class="login">
      <form @submit.prevent="login">
        <input v-model="username" type="text" placeholder="Username" required>
        <input v-model="password" type="password" placeholder="Password" required>
        <button type="submit">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  const apiBaseUrl = process.env.VUE_APP_API_URL || 'http://localhost:3000/api';

  export default {
    name: 'Login',
    data() {
      return {
        username: '',
        password: ''
      }
    },
    methods: {
      async login() {
        try {
          const response = await axios.post(`${apiBaseUrl}/login`, {
            username: this.username,
            password: this.password
          });
          localStorage.setItem('token', response.data.token);
          this.$router.push('/');
        } catch (error) {
          console.error('Login failed:', error);
          alert('Login failed. Please try again.');
        }
      }
    }
  }
  </script>