<template>
  <select :value="value" @change="changeLanguage">
    <option value="en">English</option>
    <option value="zh">中文</option>
    <option value="ko">한국어</option>
    <option value="vi">Tiếng Việt</option>
    <option value="ar">العربية</option>
    <option value="ru">Русский</option>
    <option value="de">Deutsch</option>
    <option value="fr">Français</option>
  </select>
</template>

<script>
export default {
  name: 'LanguageSelector',
  props: ['value'],
  methods: {
    changeLanguage(event) {
      this.$emit('language-changed', event.target.value);
    }
  }
}
</script>
  
  <style scoped>
  select {
    padding: 0.5em;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    font-size: 1em;
  }

  .dark-theme select {
    background-color: #333;
    color: white;
    border-color: #666;
  }
  </style>