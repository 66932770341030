<template>
    <div class="contact">
      <h2>{{ contactTitle }}</h2>
      <p>{{ nameLabel }}: {{ localizedName }}</p>
      <p>GitHub: <a href="https://github.com/pefbrute" target="_blank">@pefbrute</a></p>
      <p>Email: <a href="mailto:thedenglish@gmail.com">thedenglish@gmail.com</a></p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Contact',
    props: {
      language: {
        type: String,
        default: 'en'
      }
    },
    computed: {
      contactTitle() {
        switch(this.language) {
          case 'en': return 'Contact';
          case 'zh': return '联系';
          case 'ko': return '연락처';
          case 'vi': return 'Liên hệ';
          case 'ar': return 'اتصل بنا';
          case 'de': return 'Kontakt';
          case 'fr': return 'Contact';
          default: return 'Контакты';
        }
      },
      nameLabel() {
        switch(this.language) {
          case 'en': return 'Name';
          case 'zh': return '姓名';
          case 'ko': return '이름';
          case 'vi': return 'Tên';
          case 'ar': return 'الاسم';
          case 'de': return 'Name';
          case 'fr': return 'Nom';
          default: return 'Имя';
        }
      },
      localizedName() {
        switch(this.language) {
          case 'en': return 'Fedor Pasynkov';
          case 'zh': return '费多尔·帕辛科夫';
          case 'ko': return '표도르 파신코프';
          case 'vi': return 'Fedor Pasynkov';
          case 'ar': return 'فيودور باسينكوف';
          case 'de': return 'Fjodor Passynkow';
          case 'fr': return 'Fiodor Passynkov';
          default: return 'Пасынков Фёдор Андреевич';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .contact {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  a {
    color: #2ecc71;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>
  