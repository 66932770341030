<template>
    <script type="application/ld+json">
      {{ structuredData }}
    </script>
  </template>
  
  <script>
  export default {
    name: 'StructuredData',
    props: ['newsItem'],
    computed: {
      structuredData() {
        return JSON.stringify({
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": this.newsItem.title,
          "datePublished": this.newsItem.createdAt,
          "dateModified": this.newsItem.updatedAt || this.newsItem.createdAt,
          "author": {
            "@type": "Organization",
            "name": "Russian Headlines"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Russian Headlines",
            "logo": {
              "@type": "ImageObject",
              "url": "https://russianheadlines.com/logo.png"
            }
          },
          "description": this.newsItem.content.substring(0, 200) + "...",
          "url": `https://russianheadlines.com/${this.$route.params.lang}/news/${this.newsItem.id}`,
          "inLanguage": this.$route.params.lang,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://russianheadlines.com/${this.$route.params.lang}/news/${this.newsItem.id}`
          }
        });
      }
    }
  }
  </script>