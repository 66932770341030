<template>
  <div id="app" :class="{ 'dark-theme': isDarkTheme }">
    <div class="header-container">
      <Header :language="currentLanguage" />
      <ThemeToggle class="theme-toggle-position" @theme-changed="updateTheme" />
      <LanguageSelector class="language-selector-position" :value="currentLanguage" @language-changed="updateLanguage" />
    </div>
    <router-view :language="currentLanguage"></router-view>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import ThemeToggle from './components/ThemeToggle.vue'
import LanguageSelector from './components/LanguageSelector.vue'

export default {
  name: 'App',
  components: {
    Header,
    ThemeToggle,
    LanguageSelector
  },
  data() {
    return {
      isDarkTheme: true,
      currentLanguage: 'en',
      userTimezone: 'UTC'
    }
  },
  methods: {
    updateTheme(isDark) {
      this.isDarkTheme = isDark;
    },
    updateLanguage(lang) {
      this.currentLanguage = lang;
      localStorage.setItem('language', lang);
      this.$router.push(`/${lang}${this.$route.path.substr(3)}`);
    },
    setTimezone(timezone) {
      this.userTimezone = timezone;
    }
  },
  mounted() {
    const storedTheme = localStorage.getItem('darkTheme');
    this.isDarkTheme = storedTheme === null ? true : storedTheme === 'true';
    document.body.classList.toggle('dark-theme', this.isDarkTheme);

    this.currentLanguage = this.$route.params.lang || 'en';
    localStorage.setItem('language', this.currentLanguage);

    this.$root.$on('set-timezone', this.setTimezone);
  },
  watch: {
    '$route'(to) {
      this.currentLanguage = to.params.lang || 'en';
    }
  }
}
</script>

<style>
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s;
}

body {
  color: #333;
  background-color: #f8f8f8;
}

body.dark-theme {
  color: #f0f0f0;
  background-color: #333;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #357ae8;
}

input, textarea {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

.dark-theme a {
  color: #58a6ff;
}

.dark-theme button {
  background-color: #4a4a4a;
  color: #f0f0f0;
}

.dark-theme button:hover {
  background-color: #5a5a5a;
}

.dark-theme input, .dark-theme textarea {
  background-color: #4a4a4a;
  color: #f0f0f0;
  border-color: #666;
}

.header-container {
  position: relative;
}

.theme-toggle-position {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1001;
}

[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

html[lang="ar"] {
  direction: rtl;
}
</style>