<template>
    <div class="login-page">
      <h2>Login</h2>
      <Login @login="handleLogin" />
    </div>
  </template>
  
  <script>
  import Login from './Login.vue'
  
  export default {
    name: 'LoginPage',
    components: {
      Login
    },
    methods: {
      handleLogin() {
        this.$router.push('/');
      }
    }
  }
  </script>
  
  <style scoped>
  .login-page {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>