<template>
  <div class="news-list">
    <div v-if="isLoggedIn">
      <button @click="addNewNews" v-if="isAdmin">Add New News</button>
      <button @click="createNewsWithModel" v-if="isAdmin">Создать новость через модель</button>
      <button @click="logout">Logout</button>
    </div>
    <div v-if="displayedNews.length === 0 && !loading && !hasMoreNews" class="no-news">
      No news items available.
    </div>
    <template v-if="displayedNews.length > 0">
      <NewsItem 
        v-for="item in displayedNews"
        :key="item.id"
        :item="item" 
        :isAdmin="isAdmin"
        :language="language"
        @update="updateNews"
        @delete="deleteNews"
      />
    </template>
    <div v-if="loading" class="loading">Loading...</div>
  </div>
</template>

<script>
import NewsItem from './NewsItem.vue'
import axios from 'axios'

const apiBaseUrl = process.env.VUE_APP_API_URL || 'http://localhost:3000/api';

console.log('API Base URL:', apiBaseUrl);

export default {
  name: 'NewsList',
  components: {
    NewsItem
  },
  props: {
    language: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      news: [],
      displayedNews: [],
      isLoggedIn: false,
      isAdmin: false,
      page: 1,
      perPage: 3,
      totalNews: 0,
      loading: false
    }
  },
  computed: {
    hasMoreNews() {
      return this.displayedNews.length < this.totalNews;
    }
  },
  created() {
    console.log('NewsList component created');
    this.hasMoreNews = true;
    this.loadNews();
    this.checkLoginStatus();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async loadNews() {
      if (this.loading || (this.hasMoreNews === false && this.displayedNews.length > 0)) return;
      
      this.loading = true;
      try {
        console.log(`Fetching news: page ${this.page}, perPage ${this.perPage}`);
        const response = await axios.get(`${apiBaseUrl}/news`, {
          params: {
            page: this.page,
            per_page: this.perPage
          }
        });
        console.log('API response:', response.data);
        
        if (response.data.news && response.data.news.length > 0) {
          this.displayedNews = [...this.displayedNews, ...response.data.news];
          this.totalNews = response.data.total;
          this.$root.$emit('set-timezone', response.data.userTimezone);
          this.page += 1;
          this.hasMoreNews = this.displayedNews.length < this.totalNews;
        } else {
          this.hasMoreNews = false;
        }
      } catch (error) {
        console.error('Error loading news:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      const bottomOfWindow = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      console.log('Scroll event:', { 
        windowHeight: window.innerHeight,
        scrollY: window.scrollY,
        documentHeight: document.documentElement.scrollHeight,
        bottomOfWindow
      });
      if (bottomOfWindow) {
        console.log('Reached bottom of window, loading more news');
        this.loadNews();
      }
    },
    async addNews(title, content, source) {
      try {
        const response = await axios.post(`${apiBaseUrl}/news`, { 
          title, 
          content, 
          source,
          date: new Date().toISOString().split('T')[0] // Add current date in YYYY-MM-DD format
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.news.unshift(response.data);
      } catch (error) {
        console.error('Error adding news:', error);
        if (error.response && error.response.status === 401) {
          alert('You are not authorized to add news. Please log in again.');
          this.logout();
        } else {
          alert('Failed to add news. Please try again.');
        }
      }
    },
    async updateNews(updatedItem) {
      try {
        const response = await axios.put(`${apiBaseUrl}/news/${updatedItem.id}`, updatedItem, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        // Update the item in the local news array
        const index = this.news.findIndex(item => item.id === updatedItem.id);
        if (index !== -1) {
          // Ensure all language versions are updated
          const languages = ['En', 'Zh', 'Ko', 'Vi', 'Ar', 'Ru', 'De', 'Fr'];
          languages.forEach(lang => {
            this.news[index][`title${lang}`] = response.data[`title${lang}`] || updatedItem.title;
            this.news[index][`content${lang}`] = response.data[`content${lang}`] || updatedItem.content;
          });
          
          // Update other properties
          this.news[index] = { ...this.news[index], ...response.data };
        }
        
        console.log('News updated successfully');
      } catch (error) {
        console.error('Error updating news:', error);
        alert('Failed to update news. Please try again.');
      }
    },
    checkLoginStatus() {
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;
      this.isAdmin = !!token; // Set isAdmin to true if the user is logged in
    },
    logout() {
      localStorage.removeItem('token');
      this.isLoggedIn = false;
      this.isAdmin = false;
      this.$router.push('/login');
    },
    addNewNews() {
      const title = prompt('Введите заголовок новости (на русском):');
      const content = prompt('Введите содержание новости (на русском):');
      const source = prompt('Введите источник новости (ссылку):');
      if (title && content) {
        this.addNews(title, content, source);
      }
    },
    async deleteNews(id) {
      try {
        await axios.delete(`${apiBaseUrl}/news/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.news = this.news.filter(item => item.id !== id);
        console.log('News deleted successfully');
      } catch (error) {
        console.error('Error deleting news:', error);
        if (error.response && error.response.status === 401) {
          alert('You are not authorized to delete news. Please log in again.');
          this.logout();
        } else {
          alert('Failed to delete news. Please try again.');
        }
      }
    },
    async createNewsWithModel() {
        const text = prompt('Введите текст для создания новости:');
        if (text) {
            try {
                const response = await axios.post(`${apiBaseUrl}/generate-news`, { text }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const { title, content } = response.data;
                const source = prompt('Введите источник новости (ссылку):');
                await this.addNews(title, content, source);
                alert('Новость успешно создана!');
            } catch (error) {
                console.error('Error generating news:', error);
                alert('Не удалось сгенерировать новость. Пожалуйста, попробуте еще раз.');
            }
        }
    }
  }
}
</script>

<style scoped>
.news-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1em;
}

button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 0.5em 1em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #27ae60;
}

.loading {
  text-align: center;
  margin-top: 1em;
  font-style: italic;
  color: #666;
}

.no-news {
  text-align: center;
  margin-top: 2em;
  font-style: italic;
  color: #666;
}

.dark-theme .no-news {
  color: #aaa;
}
</style>